import React from "react";
import { Link } from "react-router-dom";
import LogoFooter from "../../assets/images/logo-footer.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";
import { PATHS } from "../../constants/Paths";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const expression = "footer";

  return (
    <>
      <footer>
        <div className="top-blk">
          <Container>
            <Row>
              <Col lg={3} xl={2}>
                <Link to="#" className="footer-logo-wrap">
                  <img
                    className="footer-logo"
                    src={LogoFooter}
                    alt="Lifestyle Logo"
                  />
                </Link>
              </Col>

              <Col lg={9} xl={10} className="mt-lg-0 right-blk">
                <div className="download-app-blk">
                  <h3 className="title">Download the App</h3>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.lifestyle.anona.diet"
                    target="_blank"
                    className="download google-play"
                    rel="external"
                  >
                    Google Play
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/anona-diet/id1661103386"
                    target="_blank"
                    className="download app-store"
                    rel="external"
                  >
                    App Store
                  </a>
                </div>

                <div className="contact-us-blk">
                  <h3 className="title">{t(`${expression}.contact`)}</h3>
                  <div className="contact-inner-blk">
                    <div className="contant-blk">
                      <a className="tel" href="tel:+965-22070030">
                        {t(`header.mobile`)}
                      </a>

                      <a className="email" href="mailto:hello@anonadiet.com">
                        hello@anonadiet.com
                      </a>
                    </div>

                    <div className="faq-terms-blk">
                      <Link to={PATHS.FAQ}>{t(`${expression}.faq`)}</Link>
                      <Link to={PATHS.TERMS_AND_CONDITIONS}>
                        {t(`${expression}.toc`)}
                      </Link>
                      <Link to={PATHS.PRIVACY_POLICY}>Privacy Policy</Link>
                    </div>

                    <div className="social-blk">
                      <h5 className="follow-us">{t(`${expression}.follow`)}</h5>
                      <ul className="social-share">
                        <li>
                          <a
                            href="https://www.facebook.com/anona.diet"
                            target="_blank"
                            className="icon-social icon-fb"
                            title="Facebook"
                          ></a>
                        </li>

                        <li>
                          <a
                            href="https://www.instagram.com/anonadiet/"
                            target="_blank"
                            className="icon-social icon-instagram"
                            title="Instagram"
                          ></a>
                        </li>

                        <li>
                          <a
                            href="https://www.tiktok.com/@anonadiet"
                            target="_blank"
                            className="icon-social icon-tiktok"
                            title="Tiktok"
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom-blk">
          <Container>
            <Row>
              <Col>
                <p className="mb-0 rights-reserved">
                  {t(`${expression}.allRights`)}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
}

export default Footer;
